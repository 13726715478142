import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import moment from "moment";

const GameResultTable = ({ dayGameData }) => {
  const [data, setGameData] = useState([]);

  var todayDate = moment().tz("Asia/Kolkata").format("LLL");
  const currentTime = moment().format("HH:mm");
  const currentDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    setGameData(dayGameData);
  }, [dayGameData]);

  // based on current date and time get data
  const getTodayResult = (gameData) => {
    const itemTime = moment(gameData.open_time, "HH:mm");
    const currentMoment = moment(currentTime, "HH:mm");

    if (gameData?.curr_date?.date === currentDate) {
      return currentMoment.isSameOrAfter(itemTime)
        ? gameData?.curr_date?.result || ""
        : "";
    }
    return "";
  };

  return (
    <div className="dayCompoResult text-center mt-2 game-result-table">
      <div className="heading-container ">
        <h6 className="fw-bold">SATTA KING LIVE RESULT</h6>
        <h6>{todayDate}</h6>
      </div>
      <div className="col-12 mt-3 ">
        <div className="row">
          {data.map((gameData, index) => (
            <div className="col-md-6 col-sm-12 game-card">
              <div className="">
                <h6 className="game-name">{gameData?.game_name}</h6>
                <span> {gameData?.open_time} </span>
                <div className="resultGame">
                  <span className="previous-result">
                    {gameData?.prev_date?.result || ""}
                  </span>
                  <i className="fas fa-globe"></i>
                  <span className="current-result">
                    {getTodayResult(gameData)}
                  </span>
                  <i className="fas fa-check-circle correct-icon"></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GameResultTable;
