function Faq() {
  return (
    <div className="disclaimer faq text-center bg-white">
      <h2>Frequently Asked Questions (FAQs)</h2>
      <h3>
        1. What is{" "}
        <a className="text-danger" href="/">
          WWW.SATTA-KING.ORG
        </a>
      </h3>
      <p className="text-dark">
        {" "}
        <span>
          {" "}
          <a href="/"> WWW.SATTA-KING.ORG</a>
        </span>{" "}
        is an online platform where users can access information about satta
        games, strategies, and updates. We aim to provide the most recent and
        accurate information about the satta industry.
      </p>
      <h3>2. How do I register on the website?</h3>
      <p>
        To register, click on the "Sign Up" or "Register" button on our
        homepage. Fill in the required details and follow the on-screen
        instructions to complete the registration process.
      </p>
      <h3>3. Is it legal to use this website?</h3>
      <p>
        Online betting laws vary by jurisdiction. It is the responsibility of
        users to be aware of and comply with their local laws regarding online
        gambling. Please consult your local regulations before using our
        website.
      </p>
      <h3>4. How is my personal data protected?</h3>
      <p>
        We take user privacy very seriously. All personal data is stored
        securely, and we employ measures to protect against unauthorized access.
        For more details, please refer to our Privacy Policy.
      </p>
      <h3>5. Can I delete my account?</h3>
      <p>
        Yes, you can request account deletion by contacting our support team.
        Please note that some information might be retained for legal or
        operational reasons.
      </p>
      <h3>6. How do I deposit and withdraw money?</h3>
      <p>
        PhonePay, PayTM, Credit Card, Debit Card, To deposit or withdraw money,
        go to the 'Wallet' or 'Account' section on our website. Follow the
        instructions provided. Ensure you are familiar with our terms regarding
        deposits and withdrawals.
      </p>
      <h3>7. Is there a minimum deposit or withdrawal amount?</h3>
      <p>
        Yes, the minimum deposit is 100-RUPAY and the minimum withdrawal is
        1000-RUPAY.
      </p>
      <h3>8. What should I do if I encounter a problem?</h3>
      <p>
        Our website is not intended for individuals under the age of 18. We do
        not knowingly collect personal information from children under 18.
      </p>

      <h3>8. Changes to This Privacy Policy:</h3>
      <p>
        If you face any issues, please contact our support team at
        (support@satta-king.org) or use the "Contact Us" form on our website.
      </p>
      <h3>9. Do you promote responsible gambling?</h3>
      <p>
        Absolutely. We believe in promoting a responsible gambling environment.
        If you or someone you know has a gambling problem, please seek help and
        guidance immediately.
      </p>
    </div>
  );
}

export default Faq;
