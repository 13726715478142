import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Disclaimer from "./disclaimer";
import Terms from "./terms";
import Privacy from "./privacy";
import Faq from "./faq";
import App from "../App";
import Footer from "./footerDrop";

const AllFooterButtonPage = () => {
  const location = useLocation();
  const isDisc = location.pathname.includes("/disclaimer");
  const isTerm = location.pathname.includes("/terms-conditions");
  const isPrivacy = location.pathname.includes("/privacy-policy");
  const isFaq = location.pathname.includes("/faq");
  return (
    <div className="pt-4 pb-3">
      {!(isDisc || isFaq || isPrivacy || isTerm) && (
        <>
          <App />
          <Footer />
        </>
      )}
      {(isDisc || isFaq || isPrivacy || isTerm) && (
        <div className="col-12 text-center bg-white w-100 border">
          <a href="/" className="" style={{ textDecoration: "none" }}>
            <h3 className="text-light bg-secondary p-3 pb-3">Home</h3>
          </a>
        </div>
      )}
      <Routes>
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>

      {/* <FooterButton /> */}
    </div>
  );
};

export default AllFooterButtonPage;
