function Terms() {
  return (
    <div className="disclaimer terms text-center bg-white">
      <h2>
        <a href="/">WWW.SATTA-KING.ORG</a>
      </h2>
      <h3> 1. Terms and Conditions</h3>
      <p>
        By accessing and using{" "}
        <span>
          {" "}
          <a href="/">WWW.SATTA-KING.ORG</a>
        </span>
        , you accept and agree to be bound by the terms and conditions contained
        herein ("Terms of Service"). If you do not agree to these terms, you
        should not use or access this website..
      </p>
      <h3>2. Eligibility:</h3>
      <p>
        Users must be 18 years of age or older to use this website. By using{" "}
        <span>
          {" "}
          <a href="/">WWW.SATTA-KING.ORG</a>
        </span>
        , you confirm that you are of legal age.
      </p>
      <h3>3. Jurisdiction:</h3>
      <p>
        This website is not intended for use in jurisdictions where online
        gambling or betting is prohibited. Users are responsible for complying
        with their local laws.
      </p>
      <h3>4. Accuracy:</h3>
      <p>
        While we strive to provide accurate and up-to-date information, we do
        not guarantee the correctness, timeliness, or completeness of the
        content available on the website.
      </p>
      <h3>5. Account Security:</h3>
      <p>
        Users are responsible for maintaining the confidentiality of their
        account and password and for restricting access to their computer.
      </p>
      <h3>6. Responsible Gambling:</h3>
      <p>
        Gambling should always be done responsibly. If you feel you have a
        gambling problem or know someone who does, seek professional help.
      </p>
      <h3>7. Limitation of Liability:</h3>
      <p>
        {" "}
        <span>
          <a href="/">WWW.SATTA-KING.ORG</a>
        </span>{" "}
        and its affiliates are not responsible for any loss or damage resulting
        from the use of the website or any of its content.
      </p>

      <h3>8. Changes to Terms:</h3>
      <p>
        We reserve the right to modify or change the Terms of Service without
        prior notice. It's the user's responsibility to review the Terms of
        Service regularly.
      </p>
      <h3>9. Links to Third-Party Websites:</h3>
      <p>
        This website may contain links to third-party sites. We do not endorse
        or assume responsibility for the content or practices of these sites.
      </p>
      <h3>10. Termination:</h3>
      <p>
        We reserve the right to terminate or suspend access to our website
        without prior notice, for any reason, including breach of these Terms of
        Service.
      </p>
      <h3>11. Governing Law:</h3>
      <p>
        These terms and conditions are governed by the laws of INDIA, without
        regard to its conflict of law provisions.
      </p>
    </div>
  );
}

export default Terms;
