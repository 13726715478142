function Privacy() {
  return (
    <div className="disclaimer peivacy text-center bg-white pt-3">
      <h2>Privacy Policy Our Website</h2>
      <p className="text-dark">
        This Privacy Policy outlines how <a href="/">WWW.SATTA-KING.ORG</a>{" "}
        ("we", "our", or "us") collects, uses, stores, and discloses information
        from users ("you") of the <a href="/">WWW.SATTA-KING.ORG</a> website.
      </p>
      <h3>1. Information Collection:</h3>
      <p>
        a. Personal Data: We may collect personal information such as your name,
        email address, postal address, phone number, and other relevant details
        when you register on our site or fill out a form. b. Non-Personal Data:
        We may collect non-personal information about your interaction with our
        site, such as your IP address, browser type, operating system, and other
        analytics data.
      </p>
      <h3>2. Use of Collected Information:</h3>
      <p>
        We may use the information collected for various purposes including: a.
        To process transactions. b. To improve our website based on feedback and
        data. c. To send periodic emails or notifications. d. To respond to
        inquiries or customer service requests.
      </p>
      <h3>3. Protection of Information:</h3>
      <p>
        We implement a variety of security measures to maintain the safety of
        your personal information when you enter, submit, or access your
        personal information.
      </p>
      <h3>4. Cookies:</h3>
      <p>
        Our website may use "cookies" to enhance user experience. You can choose
        to disable cookies through your browser settings, but this may affect
        how you can interact with our site.
      </p>
      <h3>5. Sharing Information:</h3>
      <p>
        We do not sell, trade, or rent users' personal identification
        information to others. We may share generic aggregated demographic
        information not linked to any personal identification information with
        our partners, trusted affiliates, and advertisers.
      </p>
      <h3>6. Third-Party Links:</h3>
      <p>
        Our site may contain links to other websites. We are not responsible for
        the content or privacy practices of these external sites.
      </p>
      <h3>7. Children's Privacy:</h3>
      <p>
        Our website is not intended for individuals under the age of 18. We do
        not knowingly collect personal information from children under 18.
      </p>

      <h3>8. Changes to This Privacy Policy:</h3>
      <p>
        We reserve the right to update or change our Privacy Policy at any time.
        We will notify users of any changes by posting the updated policy on
        this page.
      </p>
      <h3>9. Contact Us:</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at (support@satta-king.org)
      </p>
    </div>
  );
}

export default Privacy;
