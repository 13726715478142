import React from "react";
import { useNavigate } from "react-router-dom";

const Bottom = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="col-12 pt-2">
      <div className="bg-secondary">
        <div className="row">
          <div className="col-md-6 col-sm-12 text-center text-white">
            <div className="about-section">
              <h3 className="mb-2">ABOUT OUR WEBSITE</h3>
              <p className="m-auto fs-6 fw-bold mb-3">
                In our website you will get content related to satta king like
                satta result, satta records chart, gali disawar ghaziabad
                faridabad game and our top guesser's guessing game. If you visit
                our site then you are in any way benefit or loss. So we will not
                have any dealings with it, in many places in India it is legal
                to play Satta Matka, so you can do anything out of your
                control..
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 text-center text-white">
            <div className="useful-links d-flex flex-column usefull-links">
              <h3>USEFULL LINK</h3>
              <span
                className="footer-link m-2"
                onClick={() => handleNavigation("/disclaimer")}
                style={{ cursor: "pointer" }}
              >
                ⚠️ DISCLAIMER
              </span>
              <span
                className="footer-link m-2"
                onClick={() => handleNavigation("/terms-conditions")}
                style={{ cursor: "pointer" }}
              >
                ➡️ WEBSITE TnC
              </span>
              <span
                className="footer-link m-2"
                onClick={() => handleNavigation("/privacy-policy")}
                style={{ cursor: "pointer" }}
              >
                🔏 SATTAKING PRIVACY
              </span>
              <span
                className="footer-link m-2"
                onClick={() => handleNavigation("/faq")}
                style={{ cursor: "pointer" }}
              >
                ⁉️ FAQ SATTAKING
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center">
        <div className="copyright-section ">
          <p className="mb-0">
            Copyright © Satta-King.Org 2020 All Rights Reserved.{" "}
          </p>
          <p className="mb-0">WWW.SATTA-KING.ORG</p>
        </div>
      </div>
    </div>
  );
};

export default Bottom;
