function Disclaimer() {
  return (
    <div className="disclaimer text-center bg-white pt-3">
      <h2>Disclaimer</h2>
      <h3> 1. General Information Only:</h3>
      <p>
        The content on this website is for informational purposes only. We do
        not guarantee the accuracy, completeness, or reliability of the
        information provided. Users are advised to verify any information they
        find here before making any decisions based on it.
      </p>
      <h3>2. No Liability:</h3>
      <p>
        Under no circumstances will <a href="/">WWW.SATTA-KING.ORG</a> or its
        affiliates, partners, or employees be held liable for any loss, damage,
        or harm resulting from the use of information, products, or services
        provided on this website.
      </p>
      <h3>3. Not an Endorsement:</h3>
      <p>
        References to specific games, odds, strategies, or companies on this
        website do not constitute an endorsement or recommendation by{" "}
        <a href="/"> WWW.SATTA-KING.ORG.</a>
      </p>
      <h3>4. Local Laws Apply:</h3>
      <p>
        Online gambling may be illegal in some jurisdictions. It is the user's
        responsibility to be aware of and comply with local laws regarding
        online gambling. By accessing and using this website, users affirm that
        they are acting within the legal boundaries of their local jurisdiction.
      </p>
      <h3>5. Age Restrictions:</h3>
      <p>
        You must be 18 years or older to use this website. By accessing and
        using this website, you affirm that you are of legal age.
      </p>
      <h3>6. Gambling Awareness:</h3>
      <p>
        Gambling should be done in moderation and never as a means to generate
        income. If you or someone you know has a gambling problem, seek help.
      </p>
      <h3>7. Links to Third-Party Websites:</h3>
      <p>
        This website may contain links to third-party websites. These links are
        provided for convenience only and do not imply any endorsement or
        association with their operators. We are not responsible for the content
        or policies of third-party websites.
      </p>
    </div>
  );
}

export default Disclaimer;
