import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import GameResultTable from './JS/game_result_table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './JS/footerDrop';
import Bottom from './JS/bottom';
import Disclaimer from './JS/disclaimer';
import Terms from './JS/terms';
import Privacy from './JS/privacy';
import Faq from './JS/faq';
import AllFooterButtonPage from './JS/allFooterButtonPages';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className='container-fluid '>
    < Router >
      {/* <App />
      <Footer /> */}
      <AllFooterButtonPage />
      <Bottom />
    </Router>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
